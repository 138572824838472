import React from "react";
import { Helmet } from "react-helmet";

function NoRobots() {
  return (
    <Helmet
      meta={[
        {
          name: "googlebot",
          content: "noindex",
        },
        {
          name: "robots",
          content: "noindex",
        },
      ]}
    />
  );
}

export default NoRobots;
