import React from "react";
import { graphql } from "gatsby";

import styled from "styled-components";
import WorkItem from "../components/work-item";
import MotionHeadline from "../components/motion/motion-headline";
import NoRobots from "../components/helpers/no-robots";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    prismicWorkEnt: { data },
  },
}) {
  return (
    <RichWrap>
      <NoRobots />
      <WorkItem itemData={data} hideContent />
      <Content color={data.color}>
        {data.client && <MotionHeadline text={data.client} className="h5" />}
        {data.title.text && (
          <MotionHeadline text={data.title.text} className="h2" />
        )}
        {data.description.text && <p>{data.description.text}</p>}
      </Content>
      <Checkers className="checkers-top" />
      <Checkers className="checkers-right" />
      <Checkers className="checkers-bottom" />
      <Checkers className="checkers-left" />
    </RichWrap>
  );
}

export const Content = styled.div`
  display: grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-rows: auto auto 1fr;
  grid-gap: 1.5rem;
  overflow: hidden;
  text-align: center;
  background: var(--light);
  padding: 4vw 2vw;
`;

const Checkers = styled.span`
  position: absolute;

  /* LEFT */
  &.checkers-left {
    top: 0;
    right: 0;
    height: 100%;
    width: var(--pad);
    background: var(--v);
    z-index: 4;
  }

  /* RIGHT */
  &.checkers-right {
    top: 0;
    left: 0;
    height: 100%;
    width: var(--pad);
    background: var(--v);
    z-index: 5;
  }

  /* TOP */
  &.checkers-top {
    top: 0;
    left: 0;
    width: 100%;
    height: var(--pad);
    background: var(--h);
    z-index: 6;
  }

  /* BOTTOM */
  &.checkers-bottom {
    bottom: 0;
    left: 0;
    width: calc(100% + var(--pad));
    height: var(--pad);
    background: var(--h);
    z-index: 5;
  }
`;

const RichWrap = styled.div`
  position: relative;
  padding: var(--pad) var(--pad) 0 0;
  background: var(--light);
  z-index: 5;
  margin-bottom: -8px;

  > span {
    display: grid;
    grid-template-columns: 1fr;

    @media ${({ theme }) => theme.device.tablet} {
      grid-template-columns: 1fr 30rem;
    }
  }
`;

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query PrismicEntryPage($uid: String!) {
    prismicWorkEnt(uid: { eq: $uid }) {
      uid
      tags
      data {
        client
        color
        date
        description {
          text
        }
        title {
          text
        }
        body {
          ... on PrismicWorkEntDataBodyVideo {
            id
            slice_type
            primary {
              video {
                url
              }
            }
          }
          ... on PrismicWorkEntDataBodyImage {
            id
            slice_type
            primary {
              image {
                fluid(maxWidth: 3000) {
                  ...GatsbyPrismicImageFluid
                  aspectRatio
                }
              }
            }
          }
          ... on PrismicWorkEntDataBodyButton {
            id
            slice_type
            primary {
              external_link_text
              link {
                url
                target
              }
            }
          }
        }
      }
    }
  }
`;
